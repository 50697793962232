<template>
  <div class="view-wrapper">
    <div class="view">
      <v-card>
        <v-card-title style="padding: 4px 16px">
          <h6>Фильтр</h6>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row dense>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.startDate"
                  label="С"
                  type="date"
                  required
                  clearable

                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="filter.endDate"
                  label="По"
                  type="date"
                  required
                  clearable

                  @keydown.enter="fetchItems"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                  v-model="filter.cityKey"
                  label="Город"
                  :items="ctx.src.dict['INS_CITY'].items"
                  item-text="value"
                  item-value="key"
                  clearable
                >

                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete
                v-model="filter.organizationId"
                label="Медицинская организация"
                :items="ctx.src.org.byType('3')"
                item-text="fullName"
                item-value="id"
                clearable
                >

                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" small @click="fetchItems">
            Применить
          </v-btn>
          <v-btn small @click="resetFilter">
            Сбросить
          </v-btn>
          <v-spacer />
          <v-btn
            color="green"
            :loading="exporting"
            :disabled="items.length === 0"
            small
            class="white--text"
            @click="exp"
          >
            Экспорт
            <v-icon right dark>
              cloud_download
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
      >

      </v-data-table>
    </div>
  </div>
</template>

<script>

import dateIsoFilter from "@/filters/dateIso.filter";
import js2vm from "@/utils/js2vm";
import ctx from "@/ctx";
import fetch from "@/utils/fetch";
import vm2js from "@/utils/vm2js";

let Filter = function() {
  let self = this;

  let startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);
  self.startDate = dateIsoFilter(startDate);
  self.endDate = dateIsoFilter(new Date());
};


let Item = function(d) {
  let self = this;

  js2vm(self, d);
  self.postingDate = dateIsoFilter(d.postingDate);

}

let Report = function(d) {
  let self = this;

  self.title = d.title;
  self.subtitle = d.subtitle;
  self.rows = [];

  d.rows.forEach(i => {
    self.rows.push(new Item(i));
  });
};

export default {
  data: () => ({
    ctx,
    selected: [],
    loading: false,
    headers: [
      {
        text: "Медицинское учреждение",
        align: "start",
        sortable: false,
        value: "organizationName"
      },
      {
        text: "Город",
        sortable: false,
        value: "cityName"
      },
      {
        text: "Номер счета",
        sortable: true,
        value: "invoiceNumber"
      },
      {
        text: "Скидка, %",
        sortable: false,
        value: "discountedPercent"
      },
      {
        text: "Сумма",
        sortable: false,
        value: "amount"
      },
      {
        text: "Сумма с учетом скидки",
        sortable: false,
        value: "discountedAmount"
      },
      {
        text: "Дата проведения",
        sortable: false,
        value: "postingDate"
      }

    ],
    report: {},
    filter: new Filter(),
    items: [],
    exporting: false
  }),
  methods: {
    resetFilter() {
      this.filter = new Filter();
    },
    async exp() {
      this.exporting = true;
      const res = await fetch.post(
        "/api/report/by-invoice/export",
        vm2js(this.report)
      );
      if (res) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob);
        link.download = res.filename;
        link.click();
      }
      this.exporting = false;
    },
    async fetchItems() {
      this.loading = true;
      this.items = [];
      let res = await fetch.post(
        "/api/report/by-invoice/get",
        vm2js(this.filter)
      );
      if (res) {
        this.report = new Report(res);
        res.rows.forEach(r => {
          this.items.push(new Item(r));
        });
      }
      this.loading = false;
    }
  }
};
</script>

<style scoped>

</style>
